<template>
  <section id="yandex-metrika-code" />
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
    const { code } = this.$route.query
    this.$http.get(`/yandex-metrika/token?code=${code}`)
      .then(response => {
        const tokens = response.data
        this.$router.push({
          name: 'yandex-metrika',
          params: { connectionId: this.$route.query.state, metrikaTokens: tokens },
        })
      })
  },
  created() {
  },
  methods: {
  },
}
</script>

<style scoped>

</style>
